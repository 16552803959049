.user-details img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.user-details {
    padding-top: 30px;
}

.details-bg {
    background-image: url("./details-bg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: white;
}

.hr {
    border: 1px groove white;
}

.contact-row {
    margin-top: 30px;
}

.map-row {
    margin-top: 30px;
    padding-bottom: 30px;
}

.details-button {
    margin-left: 10px;
}