.home-img {
   height: 89vh;
   object-fit: cover;
}

.home-button {
   margin-bottom: 25px;
}

.carousel-caption-a {
    top: 0;
    bottom: auto;
    color: white;
}

.home-h5 {
   margin-top: -25px;
}

.home-title {
   width: 100%;
   font-size: 500%;
} 

/* .first-title {
   color: black;
}

.first-subtitle {
   color: black;
} */

@media only screen and (max-width: 700px) {
   .home-title {
      font-size: 3rem;
      margin-top: 10px;
   }
   .home-h3 {
      font-size: 1.5rem;
   }
   .home-img {
      height: 86vh;
      object-fit: cover;
   }
   .home-h5 {
      font-size: 1rem;
   }
}