.signup {
    background-image: url("./mic.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    min-height: 100vh;
    color: white;
    padding-top: 30px;

}

