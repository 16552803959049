.login {
    background-image: url("./login-bg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 89vh;
    color: white;
    padding-top: 30px;

}

.login-hr {
    border: 1px groove white;
    width: 50%;
    margin: 0;
    margin-bottom: 15px;

}

.loginLink {
    color: snow;
}

.loginLink:hover {
    color: snow;
}

.loginButton {
    margin-bottom: 10px;
}

.error-message{
    padding: 5px 10px;
    color: white;
    border: 1px solid rgb(236, 31, 31, 0.5);
    border-radius: 5px;
    background-color: rgb(236, 31, 31, 0.5);
    width: 50%;
    font-size: 0.8em;
    margin-bottom: 15px;
    text-align: center;
}