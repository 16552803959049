.list-container {
    padding-bottom: 30px;
}

.list-top {
    margin-bottom: 30px;
}

.users-list {
    background-image: url("./list-bg-2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    color: white;
    padding-top: 30px;

}
